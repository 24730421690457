@import './mixins';

// Colors
@import './colors';

.parimarybtn{
  border-radius: 8px;
  background: #fff !important;
  color: #202020 !important;
  border: 1px solid #D0D5DD !important;
  padding: 8px 15px;
}
.parimarybtn:hover {
  background: #fff !important;
  color: #000 !important;
}
.parimarybtn:focus {
box-shadow: none !important;
}
.d-relative {
  position: relative;
}
i.fa.fa-trash.text-danger.icon-css {
  position: absolute;
  right: -20px;
}
.paginationWrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  > span{
    font-size: 13px;
    color: #84818A;
  }
}
.chip {
  background: #1961E4;
}
.alignTopDate div.ant-picker-input{
  top: -12px;
}
button:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none !important;
}
.react-pagination-js-default{
  ul{
    display: flex;
    align-items: center;
    column-gap: 10px;

    > li{
      margin: 0 !important;
      font-size: 13px !important;
      padding: 7px 13px !important;
      &:first-child{
        padding: 0 !important;
        &:not(.disabled){
          color: #1F7BF4 !important;
        }
        &::after{
          content: "< Previous";
        }
        a{
          display: none;
        }
      }
      &:last-child{
        padding: 0 !important;
        &:not(.disabled){
          color: #1F7BF4 !important;
        }
        &::after{
          content: "Next >";
        }
        a{
          display: none;
        }
      }
      &.page{
        border-color: #0000 !important;
        background-color: transparent !important;
        box-shadow: none !important;
        font-weight: 400 !important;
      }
  
      &.is-active{
        font-weight: 500 !important;
        background-color: #fff !important;
        border-color: #1F7BF4 !important;
        box-shadow: none !important;
        text-shadow:none !important;
        color: #202020 !important;
      }
    }
    
  }
 
}

body {
  font-family: $font1 !important;

}
a {
  color: #1961E4;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font2;
}

label {
  font-size: 15px;
    font-weight: 600;
  text-transform: capitalize;
  color: #202020;
}
.form-row>.col, .form-row>[class*=col-] {
  padding-right: 10px;
  padding-left: 5px;
  align-items: center;
}
.col_side div span {
  font-size: 16px;
  font-weight: 300;
  color: #202020;
}
:where(.css-dev-only-do-not-override-14wwjjs).ant-picker-focused {
  border-color: #e9e9e9 !important;
  // box-shadow: 0 0 0 2px rgb(255 255 255 / 10%) !important;
}
:where(.css-dev-only-do-not-override-14wwjjs).ant-picker:hover, :where(.css-dev-only-do-not-override-14wwjjs).ant-picker-focused {
  border-color: #e4e4e4 !important;
}
:where(.css-dev-only-do-not-override-14wwjjs).ant-picker-range .ant-picker-active-bar {
  background: #765aa6 !important;
}
:where(.css-dev-only-do-not-override-14wwjjs).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, :where(.css-dev-only-do-not-override-14wwjjs).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, :where(.css-dev-only-do-not-override-14wwjjs).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #765aa6 !important;
}
:where(.css-dev-only-do-not-override-14wwjjs).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #7557a63b !important;
} 
:where(.css-dev-only-do-not-override-14wwjjs).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, :where(.css-dev-only-do-not-override-14wwjjs).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: #7557a63b !important;
}
:where(.css-dev-only-do-not-override-14wwjjs).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #765aa6 !important;
}
i.fa {
  cursor: pointer;
}
.dateRangePicker{
    width: 100% !important;
    border-radius: 8px;
    padding: 21px 10px;
    border: 1px solid #DCDCDC;
    height: calc(1.5em + 0.75rem + 2px);

}
.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: red;
}

.star {
  // color: red;
  display: none;
  color: #202020;
}

.action_icons {
  display: flex;
}

a.edit_icon {
  border: 1px solid #DCDCDC;
  height: 30px;
    width: 30px;
    align-items: center;
    display: flex;
    justify-content: center;
  border-radius: 5px;
  margin-right: 6px;
  &:hover{
text-decoration: none !important;
  }
}

.mainarea {
  // background: #F6F6F6;
  // background: #80808000;
  padding: 27px;
  // height: 100vh;
  height: calc(100vh - 72px) !important;
  overflow: auto;
}

span.edit_icon {
  border: 1px solid #DCDCDC;
  height: 30px;
  width: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  // margin-left: 3px;
}
i.material-icons.view {
  font-size: 15px;
  font-weight: 600;
  color: #898989;
}
i.material-icons.edit {
  font-size: 15px;
  font-weight: 600;
  color: #898989;
}
i.material-icons.delete {
  font-size: 15px;
  font-weight: 600;
  color: #898989;
}
i.fa.fa-arrow-left {
  color: #000;
}
.tox-notifications-container {
  display: none;
}
.tox-statusbar {
  display: none !important;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #F9F9F9 !important;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-bottom: 1px solid #fff !important;
  // text-transform: capitalize;
  white-space: nowrap;
}

body {
  font-family: 'Lato', sans-serif;


}
// Status style
.user_hours.active {
  align-items: center;
  border-radius: 3px;
  display: inline-flex;
  height: 24px;
  justify-content: center;
  min-width: 66px;
  padding: 0 10px;
  border-radius: 5px;
  background-color: #425ccd;
  color: white;
}
input.ch3{
  width: 15px;
  height: 15px;
}
.user_hours.deactive {
  align-items: center;
  border-radius: 3px;
  display: inline-flex;
  height: 24px;
  justify-content: center;
  min-width: 66px;
  padding: 14px 10px;
  border-radius: 5px;
  background-color: #dc354536;
  color: red;
}

.inputWrapper {
  position: relative;

  .fa {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #84818A;
    font-size: 12px;
  }
}

.modal-title,
.dropdown-item {
  text-transform: capitalize;
}

input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #84818A !important;
  opacity: 1 !important;
  /* Firefox */
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000 !important;
  opacity: 1 !important;
}

.loaderDiv {
  z-index: 99999;
  backdrop-filter: blur(2px);
  align-items: center;
  justify-content: center;
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.pageLoader {
  max-width: 50px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #A0A4A8;
  opacity: 1;
  font-size: 14px;
  font-weight: 400;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #A0A4A8;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #A0A4A8;
}

main.main {
  background-color: #F6F6F6 !important;
}

// Loader start
img.loaderlogo {
  max-width: 50px;
  // animation: rotation 3s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

span.contract.text-capitalize.completed {
  background-color: #a1ffb5;
  padding: 5px;
  color: green;
  border: 1px solid rgb(40, 196, 40);
  border-radius: 5px;
  min-width: 85px;
  display: inline-block;
  text-align: center;
}
span.contract.text-capitalize.pending {
  background-color: #dc354536;
  padding: 5px;
  color:#bd0909;
  border: 1px solid #dc354536;
  border-radius: 5px;
  min-width: 85px;
  display: inline-block;
  text-align: center;
}


// Loader end

.btn {
  text-transform: capitalize;
  font-weight: 500;
  padding: 10px 18px;
  font-size: 14px;
  border-radius: 10px; 
}


.btn-outline-primary {
  color: #1961E4;
  border-color: #1961E4;
}

a {
  cursor: pointer;
}

.taableWrapper {
  max-height: calc(100vh - 250px);
}

button.btn.btn-primary.loginclass {
  background: #1961E4;
  border-radius: 5px;
  width: 100%;
  height: 49px;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 15px;
}

@media(max-width:768px) {
  .taableWrapper {
    max-height: calc(100vh - 300px);
  }
}

.login-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  >.container {
    max-width: 500px;

    .shadow {
      border-top: 3px solid $primaryColor;
    }

    .login-logo {
      width: 100%;
      max-width: 150px;
    }
  }
}

.bginput {
  border: 1px solid #E8E8E8 !important;
  // background: #F6F6F6 !important;
  border-radius: 8px !important;
}

.empty-box {
  padding: 15px;
  text-align: center;

  .icon-box {
    padding: 15px 30px;
    font-size: 26px;
    margin-bottom: 10px;
  }
}

h3.text-left.lgtext {
  font-weight: 600;
  font-size: 24px;
}

.paraclss {
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  color: #1961E4 !important;
}

.pointer{
  cursor: pointer;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #F6F6F6;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #F6F6F6;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #F6F6F6;
}


.modal {
  overflow-y: auto;

  .card-body,
  .modal-boldy {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }
}

.upload-btn {
  position: relative;

  >input {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}

.pagination {

  >li {
    padding: 2px;

    a {
      padding: 7px 10px;
      display: inline-block;
      border: 1px solid #E8E8E8;
      border-radius: 4px;
      font-size: 14px;
      min-width: 40px;
      text-align: center;
      color: #000;
      text-decoration: none;

    }

    &.active {
      a {
        background-color: $primaryColor;
        border-color: $primaryColor;
        color: #fff;
      }

    }

    &.disabled {
      a {
        border-color: #E8E8E8;
        color: #E8E8E8;
      }
    }
  }

}


.shine {
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  min-height: 20px;
  border-radius: 5px;
}


.shine.shineCard {
  height: 250px;
}

strong.shine {
  display: block;
  margin-bottom: 14px;
}


@keyframes shine {
  to {
    background-position-x: -200%;
  }
}



.table td, .table th {
  padding: 0.75rem;
  vertical-align: middle; 
}

// edit icon css
i.fa.fa-pencil-alt.edit {
  margin: 0px 15px;
  font-size: 18px;
  color: #1961E4;
  font-weight: 700;
}
i.fa.fa-trash {
  color: #c02e2e;
  font-size: 18px;
  font-weight: 700;
}




// Responsive Design 
.buttons_Section {
  display: flex;
  align-items: center;
  margin-right: 12px;
}
.cardList {
  margin-top: 2rem;
}
a.btn.btn-primary.mr-2 {
  white-space: nowrap;
}
.form-control {
  padding: 21px 10px;
  border:1px solid #DCDCDC;
  border-radius: 8px;
}
select.form-control.types {
  height: 43px;
  margin-right: 12px;
  width: 100%;
    max-width: 200px;
}
.new_cards {
  background: #fff;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  max-height: 300px;
  // margin-bottom: 4.2rem;
}
input.searchbar.mr-2 {
  padding: 8px;
  border-color: #cdd4da;
  border-radius: 5px;
  outline: none;
  border: 1px solid #cdd4da;
  height: 43px;
}

.d-flex.justify-content-between.align-items-center {
  flex-wrap: wrap;
}
@media only screen and (min-width:1200px) and (max-width:1400px) {
  .dropdown.addDropdown.chnageses {
    margin-top: 12px;
}
.dropdown.addDropdown.chnageset {
  margin-top: 12px;
}

  .sales_section {
    padding: 20px;
}
.graph_section {
  padding: 20px;
}
.dropdown.addDropdown.chnagest {
  margin-left: 10px !important;
}
}
@media only screen and (min-width:1001px) and (max-width:1199px) {
  .dropdown.addDropdown.chnagest {
    margin-left: 10px !important;
  }

// .dropdown.addDropdown.chnages {
//   margin-top: 10px;
// }
article.d-flex {
  margin-top: 1rem !important;
}
}
@media only screen and (min-width:991px) and (max-width:1000px) {
  .dropdown.addDropdown.chnagesg{
    margin-top: 0px;
  }
 
  .min-sidebar ~ div > .main-sidebar {
    display: none !important;
  
}
  .main-navbar.min-sidebar {
    padding-left: 60px;
    display: flex;
}
.main-sidebar {
position: absolute;
top: 143px;
 z-index: 998;
}

.main-navbar{
  padding-left: 25px;
}

.sidebar-brand.p-3.pt-4.text-left.pl-5{
  display: none;
}
.min-sidebar ~ div > main.main {
 width: 100% !important;
}

.d-flex.justify-content-between.align-items-end.mb-0 {
  display: block !important;
}
article.d-flex {
  margin-top: 1rem;
}
.main-wrapper main.main{
  width: 100% !important;
}
.d-flex.justify-content-between.align-items-end.mb-3 {
  display: block !important;
}

.selectDropdown::placeholder{
  width:100px; 
  overflow:hidden; 
  white-space:nowrap; 
  text-overflow:ellipsis;
}
span {
  font-size: 14px;
}
span.side_head {
  font-size: 14px;
}
.dropdown.addDropdown.chnagest {
  margin-left: 10px !important;
}

}

@media screen and (max-width:630px) {
  
  .dropdown.addDropdown.features.mr-2 {
    margin-top: 14px;
}
}
@media only  screen and (min-width:601px) and (max-width:621px) {
  .dropdown.addDropdown.chnages {
    margin-top: 15px !important;
}
article.d-flex {
  flex-wrap: wrap !important;
  display: flex;
}
.dropdown.addDropdown.chnagest_name {
  margin-top: 13px;
}

}
@media only  screen and (min-width:601px) and (max-width:615px) {
//   .dropdown.addDropdown.chnagesname {
//     margin-top: 15px !important;
// }
article.d-flex {
  flex-wrap: wrap !important;
  display: flex;
}
}

@media screen and (min-width:601px)and (max-width:609px) {
  .dropdown.addDropdown.region_currency {
    margin-top: 15px;
}
}
@media only screen and (min-width:601px) and (max-width:990px) {

  .min-sidebar ~ div > .main-sidebar {
    display: none !important;
  
}
.d-flex.justify-content-between.align-items-center {
  /* flex-wrap: wrap; */
  display: block !important;
}
  .main-navbar.min-sidebar {
    padding-left: 60px;
    display: flex;
}
.main-sidebar {
  position: absolute;
  top: 143px;
   z-index: 998;
  }

.main-navbar{
  padding-left: 60px;
}

.sidebar-brand.p-3.pt-4.text-left.pl-5{
  display: none;
}
.min-sidebar ~ div > main.main {
 width: 100% !important;
}

.d-flex.justify-content-between.align-items-end.mb-0 {
  display: block !important;
}
article.d-flex {
  margin-top: 1rem;
  flex-wrap: wrap;
}
.descript_editpro .ql-container .ql-snow {
  border-radius: 24px 24px 0px 0px;
  border: 1px solid #dfdfdf;
}

.descript_editpro .ql-container.ql-snow {
  border-radius: 0px 0px 24px 24px;
  border: 1px solid #dfdfdf;
}
.main-wrapper main.main{
  width: 100% !important;
}
.d-flex.justify-content-between.align-items-end.mb-3 {
  display: block !important;
}

.selectDropdown::placeholder{
  width:100px; 
  overflow:hidden; 
  white-space:nowrap; 
  text-overflow:ellipsis;
}
.selectDD {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;     
 }
 .col-12.col-sm-12.col-md-12.col-lg-6.cls {
  margin-top: 15px;
}
span {
  font-size: 14px;
}
span.side_head {
  font-size: 14px;
}
.dropdown.addDropdown.chnagest {
  margin-left: 15px;
}
}
@media screen and (max-width:908px) {
  .dropdown.addDropdown.city_status{
    margin-top: 14px;
  }
}
@media screen and (max-width:900px) {
  .dropdown.addDropdown.chnageses{
    margin-top: 15px;
  }
  .dropdown.addDropdown.city_status{
    margin-top: 14px;
  }
}
@media only screen and (max-width:767px) {
  .text-right {
    margin-bottom: 15px !important;
}
  .roleTable tr td {
    width: 150px !important;
    max-width: 150px !important;
    min-width: 150px !important;
}
  .col-md-9.head_cls {
    margin-bottom: 20px;
}
article.d-flex {
  display: flex !important;
  margin-top: 1rem !important;
  flex-wrap: wrap;
}
.d-flex.justify-content-between.align-items-center {
  /* flex-wrap: wrap; */
  display: block !important;
}
}
@media screen and (max-width:766px) {
  .dropdown.addDropdown.chnages_date {
    margin-top: 15px;
}
}

@media screen and (max-width:759px) {
  .dropdown.addDropdown.region_status{
    margin-top: 15px;
  }
}
@media screen and (max-width:751px) {
  .dropdown.addDropdown.chnagess {
    margin-top: 15px;
}
.dropdown.addDropdown.chnageses{
  margin-top: 15px;
}
}
@media screen and (max-width:748px) {
  .dropdown.addDropdown.city_region {
    margin-top: 14px;
}
.dropdown.addDropdown.city_status{
  margin-top: 14px;
}
}
@media screen and (max-width:738px) {
  .dropdown.addDropdown.chnageset {
    margin-top: 15px;
}
}
@media screen and(min-width:601px) and (max-width:695px) {
  .icons_tab {
    margin-top: 0px;
}
}
@media only screen and (min-width:528px) and (max-width:600px) {
  .dropdown.addDropdown.region_status {
    margin-top: 0px;
}
  .dropdown.addDropdown.chnagesg {
    margin-top: 15px;
}
  .dropdown.addDropdown.chnages_status{
    margin-top: 15px;
  }

.dropdown.addDropdown {
  margin-top: 15px;
}
button.btn.btn-primary.mr-2.export{
margin-left: 15px;
}
  .dropdown.addDropdown.chnagesg{
    margin-top: 15px;
  }
  .icons_tab {
    margin-top: 15px;
}
  .d-flex.justify-content-between.align-items-center {
    /* flex-wrap: wrap; */
    display: block !important;
}
  article.d-flex {
    display: block !important;
    margin-top: 1rem !important;
  }
  .min-sidebar ~ div > .main-sidebar {
    display: none !important;
  
}
  .main-navbar.min-sidebar {
    padding-left: 60px;
    display: flex;
}
.main-sidebar {
  position: absolute;
  top: 143px;
   z-index: 998;
  }

.main-navbar{
  padding-left: 60px;
}

.sidebar-brand.p-3.pt-4.text-left.pl-5{
  display: none;
}
.min-sidebar ~ div > main.main {
 width: 100% !important;
}

// proffile dropdown
// .dropdown.ml-auto {
//   top: -40px;
// }

// .headerSearch {
//   top: 10px !important;
// }

// category css
.d-flex.justify-content-between.align-items-end.mb-0 {
  display: block !important;
}
article.d-flex {
  margin-top: 1rem;
}
.d-flex.justify-content-between.mb-3 {
  display: block !important;
}


p.mb-0 {
  font-size: 13px;
}
img.listIc {
  height: 40px;
  width: 40px;
}
form.headerSearch.ml-3 {
  width:auto;
  max-width: 130px;
}
// .ml-3 {
//   width: 300px;
// }
span {
  font-size: 14px;
}
span.side_head {
  font-size: 14px;
}
article.d-flex {
  margin-top: 1rem;
  flex-wrap: wrap;
}
button#dropdownMenuButtonstatusDropdown\ reset {
  margin-top: 15px;
}

.dropdown.addDropdown.chnages {
  margin-top: 15px;
}
.dropdown.addDropdown.chnagest {
  margin-top: 15px;
}

}

@media screen and (max-width:578px) {
  .dropdown.addDropdown.chnagesapp {
    margin-top: 15px;
  }
}
@media screen and (max-width:590px) {
  button.btn.btn-primary.btnreset {
    margin-top: 15px !important;
}

a.btn.btn-primary.btnreset {
  margin-top: 15px !important;
}

}


@media only screen and (min-width:320px) and (max-width:527px) {
  .dropdown.addDropdown.region_status {
    margin-top: 0px;
}
  .icons_tab {
    margin-top: 15px;
}
.dropdown.addDropdown.chnagesg {
  margin-top: 15px;
}

  .min-sidebar ~ div > .main-sidebar {
    display: none !important;
  
}
  .main-navbar.min-sidebar {
    padding-left: 40px;
    display: flex;
}
.main-sidebar {
  position: absolute;
  top: 143px;
   z-index: 998;
  }

.main-navbar{
  padding-left: 40px;
}

.sidebar-brand.p-3.pt-4.text-left.pl-5{
  display: none;
}
.min-sidebar ~ div > main.main {
 width: 100% !important;
}

// proffile dropdown
.dropdown.ml-auto {
  top: -30px;
  right: -16px;
}

.headerSearch {
  top: 20px !important;
}



// main p-age


.d-flex.justify-content-between.align-items-end.mb-3 {
  display: block !important;
}
.nav-tabs {
  border-radius: 3px !important;
  height: 40px !important;
  background: none !important;
  border: none !important;
  margin-top: 1rem;
}
.icons_tab {
  margin: 0px !important;
}
.nav-tabs {
 border-bottom: 1px solid #ffffff; 
}
article.d-flex {
  display: block !important;
  margin-top: 1rem !important;
}

.new_cards {
  height: 100% !important;
    margin-bottom: 0rem !important;
    max-height: 500px !important;
//  height: 100%;
//   margin-bottom: 0rem !important;
}
li.list_names {
  text-align: left !important;
}
.user_proff {
  display: block !important;
}

// category css
.d-flex.justify-content-between.align-items-end.mb-0 {
  display: block !important;
}
select.form-control.types {
  margin-top: 13px;
  width: 100%;
}

.d-flex.justify-content-between.mb-3 {
  display: block !important;
}
.d-flex.justify-content-between {
  display: block !important;
}

button.btn.btn-primary.btnreset {
  margin-top: 15px;
}


// dashboard

h2.mb-1.mainHeading_cls {
  font-size: 20px;
  font-weight: 600;
}
p.grayCls.mb-0 {
  font-size: 12px;
  font-weight: 200;
  color: #898989;
  width: auto !important;
}

  a.btn.barlink.text-primary {
    // left: 8px;
    // width: 35px;
    // height: 35px;
    // padding: 7px 9px;
    left: 19px;
    width: 35px;
    height: 35px;
    padding: 7px 9px;
    top: 17px;

}
form.headerSearch.ml-3 {
  width: 100% !important;
  margin-left: 9px !important;
}
p.mb-0 {
  white-space: nowrap;
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
}
// .ml-3 {
//   width: 200px;
// }
img.listIc {
  height: 40px;
  width: 40px;
}
.borderBox.d-flex.align-items-center.justify-content-between {
  padding: 0px 15px;

}
.timeBoxs p {
  font-size: 14px;
  color: #484848;
  line-height: 17px;
}

.headerSearch .Searchbar {
  width: 100%;
  max-width: 130px;
}
// form.headerSearch.ml-3 {
//   width: 130px;
// }

// dashboard responsive
.graph_range {
  display: block;
}
p.view_graph {
  margin-top: 18px;
}


// admin user
.d-flex.justify-content-between.align-items-center {
  display: block !important;
}
span {
  font-size: 14px;
}
span.side_head {
  font-size: 14px;
}

article.d-flex a, button {
  margin-bottom: 1rem !important;
}
button.btn.btn-primary.dropdown-toggle.removeBg {
  margin-bottom: 0px !important;
}

.table_section { 
  margin-top: 10px !important;
}
.mr-2.phBottomSpace {
  margin-bottom: 10px;
}
a.btn.btn-primary.profiles {
  width: fit-content;
}
button.btn.btn-primary.mr-2.export{
margin-left: 15px;
}
.hedding {
  font-size: 21px;
}
}

@media  only screen and (min-width:320px) and (max-width:441px) {
  button.btn.btn-primary.mr-2.exports{
    margin-top: 15px;
  }
  h5.heading_holidays {
    margin-top: 30px !important;
}
}

// profile changes
input.searchbar.mr-2:focus {
  box-shadow: 0 3px 10px 0 17%;
  border-color: #1961E4;
}
a.btn.btn-primary.profiles {
  background-color: #1961E4 !important;;
  border: 1px solid #1961E4 !important;
  height: auto;
  color: #fff !important;
  border-radius: 12px;
  font-weight: 600;display: flex;
  align-items: center;
  justify-content: space-between;

}
i.material-icons.prob {
  font-size: 17px;
  margin: 0px 7px;
  color: #fff;
}
label.btn.btn-primary.delete.ml-3 {
  background-color: #fff !important;
  border-radius: 10px;
  color: #1961E4 !important;
  font-size: 14px !important;
  font-weight: 500;
}

label.btn.btn-primary.edit.ml-3 {
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
}
.btn.btn-secondary.discard {
  background-color: #fff !important;
  border: 1px solid #DCDCDC !important;
  color: #202020 !important;
  font-size: 14px !important;
  height: 43px;
  border-radius: 10px;
  font-weight: 500;
 
}
a.discard{
  text-decoration: none;
  color: #202020 !important;
  font-size: 14px;
  font-weight: 500;
 & :hover{
    color: #202020 !important; 
    font-size: 14px;
    font-weight: 500;
  }
}
.btn.btn-primary.reset {
  background-color: #fff !important;
  border: 1px solid #DCDCDC !important;
  color: #202020 !important;
  font-size: 14px !important;
  height: 43px;
  border-radius: 10px;
}
button.btn.btn-primary.edit.ml-3 {
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;

}
.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-color-type: #202020 !important;
  --bs-table-bg-type: rgb(249 249 249 / 100%) !important;
}

a.btn.barlink.text-primary.active {
  outline: none;
  border: none;
}
a.btn.barlink.text-primary:active {
  border: none;
}
p.profile_data {
  margin-bottom: 0px !important;
  font-size: 14px;
  font-weight: 400;
  color: #5A5A5A;
}
.col-md-12.inputFlex {
  line-height:30px;
}
.highlightOption {
  background: #1961E4;
  color: #fff;
}
.multiSelectContainer li:hover{
 
    background: #1961E4 !important;
  color: #fff;
}
  
  


@media  screen and (min-width:320px) and (max-width:767px) {
  .min-sidebar ~ div > .main-sidebar a.col_side:hover {
    justify-content: center;
     margin: 8px 14px !important;
}
  .min-sidebar ~ div > .main-sidebar {
    display: block !important;
    max-width: 250px;
    min-width: 250px;
    width: 100%;
    min-height: 100vh;
    margin-top: -72px;
    border-right: 1px solid #E8E8E8;
}
.sidees {
  display: flex;
}
.min-sidebar ~ div > .main-sidebar a.nav-link.hoverclass {
  padding: 9px 16px;
  border-radius: 12px !important;
  margin-right: 10px !important;
  display: flex !important;
    justify-content: left !important;
    width: auto !important;
}
.min-sidebar ~ div > .main-sidebar a.col_side {
  padding: 9px 16px;
  border-radius: 12px !important;
  margin-right: 10px !important;
  display: flex !important;
    justify-content: space-between !important;
    width: auto !important;
}
.min-sidebar ~ div > .main-sidebar .nav-link span {
  display: block;
}
.min-sidebar ~ div > .main-sidebar a.col_side span {
  display: flex;
  font-size: 15px;
  font-weight: 300;
  color: #202020;
}
.min-sidebar ~ div > .main-sidebar i {
  margin-right: 8px !important;
}
.main-sidebar {
  display: none;
}
.main-sidebar .nav .nav-item .nav-link i.sidenv {
    display: none !important;
}
.card.card-body.sides li a {
  border-radius: 4px !important;
  padding-left: 42px !important;
}
}
@media(min-width:768px){
  .main-navbar.min-sidebar ~ div > .main-sidebar{
    display: block !important;
  }
}
::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    // background: #0056b3;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background:#0056b3;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #3971ac;
  }
  .rowAdd {
    background: linear-gradient(100deg, #0069ded9 -5.85%, #0069de 109.55%);
    color: #fff;
    padding: 15px 15px;
    border-radius: 5px;
    margin-bottom: 15px;
    width: 100%;
}
.rowAdd i {
  font-size: 22px;
  cursor: pointer;
  color: #fff;
}
.rowAdd a{
  line-height: 0;
}
.name_heading{
  font-size: 20px;
}
.profiledetailscls {
  padding: 9px 10px;
  min-height: 38px;
  border-radius: 4px;
  max-height: 210px;
  overflow: auto;
  background:#0069de0a;
  color: gray;
  word-break: break-word;
  font-size: 12px;
}
.sideclass {
padding: 0px 20px;
}
.user_imgs {
  border-radius: 50%;
}

.descript_editpro .ql-container .ql-snow {
  border-radius: 24px 24px 0px 0px;
  border: 1px solid #dfdfdf;
  height:100px
}

.descript_editpro .ql-container.ql-snow {
  border-radius: 0px 0px 24px 24px;
  border: 1px solid #dfdfdf;
  height:100px
}
.css-13cymwt-control {
  min-height: 44px !important;
}

/* Custom styles for the input field */
.custom-input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.3s ease-in-out;
}

.custom-input:focus {
  border-color: #007bff;
  outline: none;
}

/* Custom styles for the select dropdown */
.custom-select {
  width: 100%;
  height: 44px;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  background-color: white;
  transition: border-color 0.3s ease-in-out;
}

.custom-select:focus {
  border-color: #007bff;
  outline: none;
}


